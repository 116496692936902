﻿.image-request-module {
    width: 520px;
    min-height: 300px;
    background-color: rgba(120, 120, 121, 0.96);
    padding: 20px 40px;
    color: #FFF;
    position: fixed;
    z-index: 800;
    top: 20%;
    left: calc(50% - 260px);

    @include breakpoint(smallTablet) {
        width: 100%;
        left: 0px;

            top: 42%;
    }



    .close-request {
        position: absolute;
        right: 7px;
        font-size: 26px;
        z-index: 500;
        cursor: pointer;
        height: auto;
        top: 7px;
    }

    h2 {
        font-family: "adelle", serif;
    }

    .form-label, .form-content {
        padding: 9px 16px;
    }

    .search-criteria {
        max-width: 100%;
        width: 100%;
        height: 41px;
        background-color: #6f6f73;
        color: #FFF;
        padding: 0px 10px !important;
        font-size: 16px;
        border: 0px;
        margin: -1px !important;
        display: inline-block;
        vertical-align: top;
        position: relative;
        line-height: 41px;

        &.active {
            background-color: #30c0c5;
        }

        .arrow-down {
            position: absolute;
            top: 2px;
            right: 16px;
        }

        .search-criteria-options {
            position: absolute;
            z-index: 300;
            background-color: #CCC;
            width: 100%;
            padding: 8px;
            left: 0px;
            top: 41px;

            .type-item {
                cursor: pointer;
                background-color: #58585b;
                color: #FFF;
                padding: 0px 10px !important;
                text-transform: uppercase;
                font-size: 14px;
                border-bottom: solid 1px #DDD;
                position: relative;

                &.active {
                    background-color: #30c0c5;
                }
            }
        }
    }

    button#request-image {
        background-color: #c2cb62;
        color: #FFF;
        padding: 10px 30px;
        border: 0px;
        font-size: 20px;
        margin: auto;
        float: none;
        margin-top: 20px;
    }
}
