﻿@import "mixins/breakpoints";
@import "variables/colors"; 
@import "variables/fonts";


.ta-editor {
  min-height: 300px;
  height: auto;
  overflow: auto;
  font-family: inherit;
  font-size: 100%;
}
.headline-holder {
    display: inline-block;
}
.lightbox-title-icons {
     display: inline-block; 
    min-width: 160px;
}

.lightbox-share-modal.lightbox-page {
       position: absolute;
    left: 122px;
    top: 53px;
    background: rgba(115, 126, 133, .97);
    color: #FFF;
    padding: 10px 10px;
    max-width: 260px;
    width: 250px;
    z-index: 304;
}


.global-edit-icon{
    cursor:pointer;
}

div.admin-home-featured .layout-holder {
    position: relative;

    &.fix-to-top {
       position: fixed;
    top: 80px;
    z-index: 3000;
    right: 14px;
    width: 170px;
}

    .global-edit-icon{
        top: 10px;
    }


    .layout-modal {
    width: 400px;
    position: absolute;
    width: 300px;
    background-color: rgba(66, 63, 63, 0.56);
    right: 0px;
    text-align: center;
    min-height: 120px;
    top: 37px;

    .close-preview.glyphicon.glyphicon-remove-circle {
    position: absolute;
    right: 6px;
    top: 12px;
    font-size: 25px;
}


    .add-row {
    background-color: #c2cb62;
    margin: 11px;
    min-height: 0px;
    padding: 5px;
  
}


    .layout-rows {
        min-height: 50px;
    background-color: #CCC;
    margin: 10px;
    padding: 0px;
      position:relative;

    .hp-col {    
    background-color: #c2cb62;
    height: 45px;
    display: inline-block;
    position: relative;
    top: 2px;
    margin: 0px !important;
    border: solid 1px #FFF;
    &.col-2{
        width: 50%;
    }
    &.col-3{
            width: 33%;
    }
    &.col-4{
            width: 25%;
    }
}
    

}

}


}

.col-md-12, .col-md-4, .col-md-6, .col-md-3 {

    &.view-ghost {
        opacity: .2;
    }

    &.lightbox-item {
        padding: 0px;

        .lightbox-align {
            min-height: 381px;
            display: inline-block;
            margin-right: -4px;
            width: 0px;
        }

        .lightbox-info {
           
            background-size: cover;
            background-position: center top;
            vertical-align: top;
            position: relative;
            cursor: pointer;

            .lightbox-title {
                min-height: 60px;
                position: absolute;
                display: inline-block;
                width: 100%;
                vertical-align: bottom;
                z-index: 150;
                background: $faded-gray;
                color: #FFF;
                padding: 8px 16px;
                bottom: 0px;

                .category {
                    display: inline-block;
                    background-color: #30c0c5;
                    font-size: 12px;
                    padding: 2px 7px 0px;
                    margin: 0px 0px 3px;
                        text-transform: uppercase;
                        border-radius: 5px;
                }

                .lightbox-subtext {
                    font-family: $base-font;
                    font-size: 16px;
                    font-weight: 700;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}


.col-md-3{
     .lightbox-info {
        min-height: 600px;
             }
    }
.col-md-4{
     .lightbox-info {
        min-height: 475px; 
        
           @include breakpoint(largeTablet) {
                  min-height: 600px;      
            }
           
        }
    }

.col-md-6{

    &.view-ghost{
        opacity:.2;
    }

&.lightbox-item {
    padding: 0px;

    .lightbox-align {
        min-height: 420px;
        display: inline-block;
        margin-right: -4px;
        width: 0px;
    }

    .lightbox-info {
        min-height: 525px;
        
        }
    }
}

.lightbox-extended {
    font-family: "adelle", serif;

    input[type=text], textarea {
        padding: 5px;
        max-width: 100%;
        width: 90%;
        resize: none;
    }

    .form-label {
        font-size: 16px;
        margin: 17px 0px 3px;
        font-weight: 800;
        color: #58585B;
    }

    label {
        margin: 16px 24px 6px 6px;
        line-height: 12px;
        position: relative;
        top: -1px;
    }

   

   
}

 .lightbox-edit.input-field {
        padding: 7px 6px;
        font-size: 16px;
        /* min-width: 200px; */
        max-width: 400px;
        width: 320px;
    }

button.manage-bttn {
    background-color: #c2cb62;
    color: #FFF;
    padding: 9px 30px;
    border: 0px;
    font-size: 16px;
    position: relative;
    top: 0px;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    text-transform: uppercase;
    margin-bottom: 60px;
}

a.image-links {
    cursor:pointer;
    text-decoration:none !important;
    &:hover{
        color: #30c0c5;
        svg{
            
      .cls-1 {
        fill: #30c0c5;
        opacity: 1;
      }
    
        }
    }
}

.col-md-6.gallery-home-img {
    width: 50%;
    display: inline-block;
}


.lightbox-save-modal {
    .navbar-menu-item.user-menu.dashboard {
        padding: 4px;

        a.bttn-lb {
            color: #FFF;
            padding: 3px 10px;
            background-color: #666;

            &.active {
                color: #FFF;
                background: #30c0c5 !important;
            }
        }
    }
}
