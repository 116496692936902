﻿
.download-page{

    .col-md-6.text-right.ng-scope {
    z-index: 300;
    position: relative;
}

    .control-input {
    display: inline-block;
    vertical-align: top;
    width: auto;
    min-width: 342px;
    padding: 0px;
}

    button.search-button {
    background-image: url(/media/images/search-button.jpg);
    width: 40px;
    height: 40px;
    border: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
}
    .search-input{
         max-width: 300px;
    width: 100%;
    height: 40px;
    background-color: #FFF;
    border: solid 1px #6F6F73;
    color: #6F6F73;
    padding: 0px 10px;
    font-size: 16px;
    margin: 0px 1px !important;
    display: inline-block;
    vertical-align: top;
    position: relative;
    }

    .date-range-selector{
        text-align:left;
            max-width: 260px;
    width: 100%;
    height: 40px;
     background-color: #FFF;
    border: solid 1px #6F6F73;
    color: #6F6F73;
    padding: 0px 10px !important;
    font-size: 16px;
   margin: 0px 1px !important;
    display: inline-block;
    vertical-align: top;
    position: relative;
    line-height: 40px;
        left: 4px;
        .arrow-down {
    position: absolute;
    top: 2px;
    right: 16px;
}
    }
}

.download-section {
    margin: 0px -30px 20px;
    position: relative;
    top: -47px;

    .collection-area {
        background-color: #f7f9f4;
        border-top: #e6e7e6 solid 2px;
        padding: 30px 60px;
    }

    .tab-collection {
        padding-left: 34px;



        .tabs {
            display: inline-block;
            position: relative;
            top: 2px;


            a {
                font-family: "adelle", serif;
                color: #bcbcbd;
                font-size: 24px;
                text-transform: uppercase;
                padding: 0px 10px;

                &:hover, &:active, &:focus {
                    text-decoration: none;
                }
                &:hover{
                     color: #58585b;
                }
            }

            &.active {
                background-color: #f7f9f4;
                border: #e6e7e6 solid 2px;
                border-bottom: none;
                padding: 10px;

                a {
                    color: #58585b;
                }
            }
        }
    }
}
