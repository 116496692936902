﻿@import "mixins/breakpoints";
@import "variables/fonts";




ul.nav.navbar-nav.public li {
    padding: 5px 20px;
    font-weight: 800;

       &.mobile-only{
            display:none;
        }

    @include breakpoint(tablet) {
        padding: 0px 0px;
        &.mobile-only{
            display:block;
        }
    }

    a {
        font-size: 16px;
     

        @include breakpoint(tablet) {
            font-size: 16px;
            float: none;
            padding: 10px 20px;
            margin: 0px;
            height: auto;

            
        }
    }
}

.home {

    .fixed-nav.ng-scope {
        min-height: 60px;
        width: 100%;
    }

    .navbar-inverse {
        background-color: rgba(141, 142, 143, 0.93);
        border: none;
        max-height: 60px;
        position: fixed;
        width: 100%;
        z-index: 4000;
    }





    .navbar-menu {
        /*background-color: rgba(133,145,154,.85);*/
        position: absolute;
        top: 63px;
        /*padding: 10px;*/
        width: 270px;
        text-transform: uppercase;

        a {
            color: #FFF;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: calc(100% - 15px);
            text-align: left;
        }

        @include breakpoint(tablet) {
            width: calc(100% - 3px);
        }
    }

    @include breakpoint(tablet) {
        #navbar {
            overflow-y: visible;
            background: rgba(115, 126, 133, 1.0);

            ul.nav.navbar-nav.public {
                margin: 0px -15px -20px;
            }

            .navbar-menu {
                /* background-color: rgba(133,145,154,.85); */
                position: absolute;
                top: 63px;
                /* padding: 10px; */
                width: 270px;
                text-transform: uppercase;
                left: 0px;
                width: 100% !important;
                min-width: 260px;

                @include breakpoint(tablet) {
                    top: 49px;
                    z-index: 300;
                }

                .navbar-menu-item {
                    padding: 10px;
                    width: 100%;
                    background-color: rgba(133, 145, 154, 1.0);
                    margin-bottom: 0px;
                    position: relative;
                    transition: opacity .1s linear;
                    border-bottom: solid 1px #f1f1f1;
                }
            }
        }
    }

    .container {
        position: relative;
        z-index: 100;
    }

    div#navbar {
        position: relative;
        z-index: 103;

        @include breakpoint(tablet) {
            position: relative;
            z-index: 103;
            background: #737e85;
        }
    }


    .navbar-inverse .navbar-nav > li > a {
        color: #FFF;
        font-family: "adelle",serif;
        /*margin-left: 30px !important;*/
        font-size: 14px;
    }

    .navbar-header {
        position: relative;
        z-index: 104;
    }

    form.navbar-form.navbar-right {
        margin-top: 0px !important;
        border: 0px;
        box-shadow: none;
    }
}
 .header-logo-div {
        width: 200px;
        height: 44px;

        img.header-logo {
            width: 100%;
            height:100%;
            margin: 8px -5px;

            @include breakpoint(tablet) {
                 width: 100%;
            height:100%;
                margin: 8px 10px;
            }
        }
    }



.exact-search {
    position: absolute;
    z-index: 400;
    width: 150px;
    right: 10px;
    top: 19px;
    color: #FFF;
    text-transform: uppercase;
    cursor:pointer;

    .option svg {
        width: 40px;
        height: 20px !important;
        position: relative;
        top: 5px;
        left: 5px;

        .cls-1 {
            fill: #FFF;
            opacity:1.0;
        }
    }
}