﻿
.home .login-controller {
    z-index: 200;
    background: rgba(115, 126, 133, .95);
    top: 94px;

    @media (max-width : 370px) {
        width: 100%;
    }
}

.home-tagline .tagline {
    font-family: "adelle", serif;
    color: #FFF;
    position: relative;
    z-index: 140;
    text-align: center;
    font-size: 1.90em;
    font-weight: 400;
    padding: 10px;
}


.home-drop {
    height: 450px;

    &.full-width {
        .lightbox-item {
            .lightbox-info {
                min-height: 450px;
                background-attachment: inherit;

                &.demo {
                    background-image: url(/image/header_1200.jpg);
                    background-color: #e4e4e4;
                    background-size: 1200px 340px !important;
                    background-position: center 110px !important;
                    background-repeat: no-repeat;
                    position: relative;
                    top: 0px;

                    @include breakpoint(largeTablet) {
                        background-image: url(/image/header_760.jpg);
                        background-size: 760px 340px !important;
                    }

                    @include breakpoint(mobile) {
                        background-image: url(/image/header_320.jpg);
                        background-size: 320px 340px !important;
                        top: 42px;
                    }
                }
            }
        }
    }
}

@include breakpoint(tablet) {
    .sub-page ul.nav.navbar-nav.public {
        position: relative;
        top: -10px;
        background-color: #666666;
    }
}

.home {
    &.hero-graphic {
        height: 450px;
    }
}

.home-search {
    top: 165px;
}


.home-tagline {
    background-color: #b3b1b1;
    top: 60px;
    bottom: unset !important;
}

.lightbox-title {
    top: 0px;
    bottom: unset !important;
}
