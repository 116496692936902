﻿@mixin breakpoint($point) {
 




     @if $point == mobilePotrait {
        @media (max-width :400px) {
            @content;
        }
    }

      @if $point == mobileMiddle {
        @media (max-width :415px) {
            @content;
        }
    }

     @if $point == mobile {
        @media (max-width :480px) {
            @content;
        }
    }

    @if $point == smallTablet {
        @media (max-width : 640px) {
            @content;
        }
    }

    @if $point == tablet {
        @media (max-width : 768px) {
            @content;
        }
    }
    @if $point == largeTablet {
        @media (max-width : 992px) {
            @content;
        }
  }
  
    @if $point == desktop {
        @media (max-width:1250px) {
            @content;
        }
    }
}
