﻿

.reset-password-form {
    font-family: "adelle", serif;

    input {
        padding: 5px;
        max-width: 100%;
        width: 90%;
        resize: none;
    }

    button {
        background-color: #c2cb62;
        color: #FFF;
        padding: 14px 30px;
        border: 0px;
        font-size: 20px;
        position: relative;
        top: 10px;
        font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
        text-transform: uppercase;
        left: -14px;
    }
    .criteria {
    color: #FF0000;
}
    .error-message{
        padding-top:20px;
    }

}
