﻿.sign-up{

     font-family: "adelle", serif;
      input, textarea {
    padding: 5px;
    max-width: 100%;
    width: 90%;
     resize: none;
}
      textarea {
      min-height:100px;    
      }

      button{
          background-color: #c2cb62;
    color: #FFF;
    padding: 14px 30px;
    border: 0px;
    font-size: 20px;
    position: relative;
    top: 20px;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    text-transform:uppercase;
    //---//
    background-color: #30c0c5;
    border-radius: 10px;

      }
}

.sign-up-label {
    padding: 10px 0px;
    font-size: 16px;
}

.col-md-8.sign-up-input {
    padding: 4px 0px;

      @include breakpoint(largeTablet) {
                padding: 4px 14px;
            }
}