﻿.image-details {
    display: inline-block;
    overflow: hidden;
    padding: 30px;
    width: 100%;

    @include breakpoint(largeTablet) {
        padding: 10px;
    }

    .related-images {
        padding-top: 40px;
    }

    .details-pane.content {
        position: relative;
        height: 100%;

        @include breakpoint(largeTablet) {
            position: relative;
        }
    }

    .spec-table.image-detail {


        .lightbox-save-modal {
            left: 52px;
            top: -60px;
        }

        .lightbox-download-modal {
            left: 92px;
            top: 0px;
        }
    }


    




    .row {

        &.padding-below {
            padding-bottom: 20px;
        }
        /*display: table-row;
        min-height: 600px;

        .col-md-8 {
            display: table-cell;
            float: none;
            vertical-align: top;
        }

        .col-md-4 {
            display: table-cell;
            float: none;
            vertical-align: top;
            min-height: 600px;
        }*/
        .image-specs {
            position: relative;
            bottom: 0px;
            width: 100%;
            padding-top: 20px;

            @include breakpoint(largeTablet) {
                position: relative;
            }

            &.portrait {
                position: relative;
                padding-top: 60px;
            }

            span.spec-label {
                font-weight: 600;
                color: #58585b;
            }

            .spec-table .row {
                /*width: 100%;
                position: relative;
                float: none;
                display: inline;
                padding: 0px;
                margin: 0px 0px;*/
            }
        }
    }
}

.box-cover-large {
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    background-size: cover;
    min-height: 400px;

         @include breakpoint(largeTablet) {
               min-height: 200px;
               padding-bottom:20px;
            }


   img {
  max-width: 100%;
    max-height: 660px;
}
}

.image-specs svg {
    width: 36px;
}



a.keywords {
    background-color: #30c0c5;
    margin: 3px 3px;
    display: inline-block;
    padding: 4px 6px;
    color: #FFF;
    text-transform: uppercase;
    font-size: .8em;
    font-weight: 600;
}


.preview-image-specs {
        .spec-table{


            .lightbox-save-modal {
                left: -298px;
                top: -300px;
            }

            .lightbox-download-modal {
                left: -252px;
                top: -98px;
            }
        }
    }