﻿
.home-lightbox-collection .gallery.lightbox-section .tabs.search-options {
    float: none;
    text-align: right;
    position: relative;
    top: -28px;
}


.galler-container {
    position: relative;
    height: 610px;
    width: 100%;
    overflow: hidden;


    .gallery-header {
        height: 10em;
        background-color: #f1f1f1;
        min-height: 610px;


        .left-preview {
            position: absolute;
            left: 7px;
            top: calc(50% - 15px);
            font-size: 45px;
            z-index: 500;
            cursor: pointer;
            height: 100%;
            top: 0px;
           color: #9b9b9d;
            &:hover{
                color:#30c0c5;
            }
            &.glyphicon {
                /*text-shadow: 0 0 5px rgba(0, 0, 0, 0.41);*/
            }

            &.glyphicon-chevron-left:before {
                position: relative;
                top: calc(50% - 15px);
            }
        }

        .right-preview {
            position: absolute;
            right: 7px;
            font-size: 45px;
            z-index: 500;
            cursor: pointer;
            height: 100%;
            top: 0px;
            color: #9b9b9d;

            &:hover{
                color:#30c0c5;
            }

            &.glyphicon {
                /*text-shadow: 0 0 5px rgba(0, 0, 0, 0.41);*/
            }

            &.glyphicon-chevron-right:before {
                position: relative;
                top: calc(50% - 15px);
            }
        }

        .gallery-holder {
            position: absolute;
            height: 610px;
            width: 100%;
            transition: 1s ease-in-out;

            &.disable-animation {
                transition: none !important;
                transition-property: none !important;
            }

            .gallery-slideshow {
                position: absolute;

                .group1 {
                    background-color: #f1f1f1;
                    position: absolute;
                    left: 50%;
                }

                .gallery-slide.ng-scope {
                    display: inline-block;
                    background-size: cover;
                }
            }
        }

        .gallery-shade {
            background-color: #FFF;
            opacity: .85;
            position: absolute;
            height: 610px;
            transition: 1s ease-in-out;

            &.left {
                left: 0px;
            }

            &.right {
                right: 0px;
            }
        }
    }


    .gallery-title {
        position: absolute;
        top: 480px;
        text-align: center;
        width: 100%;
        visibility: visible;
        opacity: 1;
        transition: opacity 0.3s, visibility 0.3s;

        &.fadeout{
             visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
        }


        .gallery-headline {
            h2 {
                font-family: "adelle", serif;
                color: #FFF;
                font-size: 30px;
                text-shadow: 0px 0px 8px #000;
            }
        }

        .gallery-subhead {
            font-family: "adelle", serif;
            color: #FFF;
            font-size: 22px;
            text-shadow: 0px 0px 8px #000;
        }
    }
}

.gallery-content{
        padding-top: 70px;
    text-align:center;
    .gallery-headline {
            h2 {
                font-family: "adelle", serif;
                color: #58585b;
                font-size: 30px;
            }
        }
        .gallery-subhead{
             font-family: "adelle", serif;
                color: #58585b;
                font-size: 22px;
        }

        .gallery-copy {
                font-family: "adelle", serif;
    font-size: 1.6em;
    padding: 30px 20%;
    text-align:left;

       @include breakpoint(mobile) {
               padding: 30px 10%;
            }

}
    
}

.gallery-buttons {
    padding: 20px 0px;
}

.headline-holder {
    .category {
        display: inline-block;
        background-color: #30c0c5;
        font-size: 12px;
        padding: 2px 7px 0px;
        margin: 0px 0px 3px;
        color: #FFF;
        margin-right: 10px;
        position: relative;
        top: -4px;
        text-transform: uppercase;

         @include breakpoint(mobile) {
               top: 10px;
            }

    }

    h3.small-header-text {
        font-family: "adelle", serif;
        color: #5b5b5e;
        font-size: 24px;
        display: inline-block;
    }
}

.gear-icon{
    font-size: 22px;
    -moz-osx-font-smoothing: grayscale;
    color: #9b9b9d;
    &.active{
        color:#30c0c5;
    }
}