﻿@import "mixins/breakpoints";
@import "variables/colors"; 
@import "variables/fonts";

.home-search {
    max-width: 630px;
    width: 90%;
    padding-bottom: 10px;
    position: relative;
    z-index: 1450;
    top: 322px;
    margin: auto;
    /*overflow: hidden;*/
    background: $faded-gray;


    .search-categories {
        position: relative;
        top: -9px;


        .search-selected-item {
            position: relative;
            display: inline-block;


            span {
                color: #c2cb62;
                font-size: .85em;
                text-transform: uppercase;
            }

            span.glyphicon {
                padding: 0px 8px 0px 5px;
                color: #b5b6b5;
            }
        }
    }

    @include breakpoint(tablet) {
        top: 152px;

        .search-fields.row {
            width: 100%;
        }
    }


    .td-fluid-search .exact-search {
        right: 40px;
        top: 5px;
    }


    .search-content {
        padding: 0px 0px 0px 20px;
        position: relative;
        z-index: 120;
        top: -7px;


        .search-options {
            position: relative;
            padding-top: 0px;

            .search-criteria {
                max-width: 100%;
                width: 100%;
                height: 41px;
                background-color: #58585b;
                color: #FFF;
                padding: 0px 10px !important;
                font-size: 16px;
                border: 0px;
                margin: -1px !important;
                display: inline-block;
                vertical-align: top;
                position: relative;
                line-height: 41px;


                &.active {
                    background-color: #30c0c5;
                }

                .arrow-down {
                    position: absolute;
                    top: 2px;
                    right: 16px;
                }
            }

            .search-criteria-options {
                position: absolute;
                z-index: 300;
                background-color: #CCC;
                width: calc(100% - 0px);
                top: 40px;
                padding: 8px;

                .search-item {
                    cursor: pointer;
                    background-color: #58585b;
                    color: #FFF;
                    padding: 10px !important;
                    text-transform: uppercase;
                    font-size: 14px;
                    border-bottom: solid 1px #DDD;
                    position: relative;

                    button {
                        width: 25px;
                        height: 25px;
                        background: none;
                        border: solid 2px #CCC;
                        position: absolute;
                        right: 10px;
                    }

                    &.active button {
                        background-color: #30c0c5;
                        border: solid 2px #FFF;
                    }
                }
            }
        }


        .search-fields.row {
            padding-left: 10px;
            width: 100%;

            .col-md-4, .col-md-8 {
                padding: 0px 2px 5px 0px;
            }
        }

        button.search-button, a.search-button {
            background-image: url(/media/images/search-button.jpg);
            width: 40px;
            height: 40px;
            border: none;
            display: inline-block;
            vertical-align: top;
        }

        select.search-drop {
            width: 100%;
            height: 41px;
            background-color: $input-background;
            color: #FFF;
            padding: 0px 10px;
            font-size: 16px;
            border: 0px;
            margin: -1px !important;
            display: inline-block;
            vertical-align: top;
        }

        input#search-input {
            width: 101%;
            height: 41px;
            background-color: $input-background;
            color: #FFF;
            padding: 0px 10px;
            font-size: 16px;
            border: 0px;
            margin: -1px !important;
            display: inline-block;
            vertical-align: top;
            max-width: 101%;
        }
    }
}