﻿


.gallery-manager {
    max-width: 500px;

    .home-drop {
        width: 100%;
        height: 300px;
        position: relative;
        top: 0px;
        overflow: hidden;
        left: 0px;
        z-index: 4;


        .lightbox-info {
            cursor: default !important;
        }

        &.full-width {
            padding: 0px;
            //border: solid 2px #FF0000;
            .lightbox-item {
                width: 100% !important;
                padding: 0px;

                .lightbox-info {
                    min-height: 300px;
                    background-size: cover;
                    background-position: center top;
                    vertical-align: top;
                    position: relative;
                    cursor: pointer;
                    background-attachment: inherit;

                    @include breakpoint(tablet) {
                        background-attachment: inherit;
                    }
                }
            }
        }
    }

    .col-md-3 .lightbox-info {
        min-height: 210px;
    }

    .col-md-4 .lightbox-info {
        min-height: 205px;
    }

    .col-md-6.lightbox-item .lightbox-info {
        min-height: 225px;
    }


    div.admin-home-featured .layout-holder {
        position: fixed;
        right: 0px;
        width: 200px;
        top: 100px;

        .global-edit-icon {
            position: fixed;
            top: 110px;
        }
    }

    div.admin-home-featured .global-edit-icon {
        position: fixed;
        top: 70px;
    }
}


.view-normal.gu-mirror, .view-ghost.gu-mirror {
    opacity: .2;
    max-height: 200px;

    .lightbox-info {
        min-height: 210px;
    }
}

.gallery-holder {
    display: inline-block;
    width: 500px;
    vertical-align: top;
}

.gallery-inactive {
    position: fixed;
    left: 540px;
    top: 70px;
    width: 500px;
    z-index: 300;
   

    .col-sm-6.col-md-3.lightbox-item.view-ghost.ng-scope {
        padding: 2px;
        opacity: .55;
        &:hover{
            opacity:1;
        }
    }

    .row.lightbox-collection {
        background-color: #FFF;
        z-index: 3000;
        position: relative;
        border: solid 2px #58585b;
        padding: 4px;
        width: 100%;
         margin: 0px;
         min-height: 655px;
    max-height: 655px;
    overflow-y: auto;
    }
    .gallery-manager-controls{
        margin-bottom:10px;
    }

    .disable-gallery.glyphicon.glyphicon-remove-circle {
    position: absolute;
    z-index: 200;
    right: -1px;
    top: 4px;
    width: 30px;
    height: 30px;
    font-size: 25px;
    background-color: #00f526;
    padding: 3px 3px;
    opacity:.25;
    cursor:pointer;

    &.archived{
        background-color: #FF0000;
    }

    &:hover{
        opacity:1;
    }

}

    select#gallery-sort {
    padding: 2px;
}
    .control-label {
    font-weight: 600;
}

}
