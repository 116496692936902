﻿
.preference-section {
    padding: 10px 30px 50px;

    @include breakpoint(smallTablet) {
        padding: 10px 10px 50px;
    }

    .pref-item {
        padding: 4px 30px;

        @include breakpoint(smallTablet) {
            padding: 4px 0px;
        }
    }

    h2 {
        font-family: "adelle", serif;
        color: #86868c;
        font-size: 26px;
        text-transform: uppercase;
    }

    h4 {
        font-family: "adelle", serif;
        color: #86868c;
        font-size: 20px;
        text-transform: uppercase;
    }

    .option {
        padding: 10px;
        position: relative;
        padding-left: 57px;
        height: 40px;
        padding-top: 0px;
        line-height: 29px;
        font-size: 17px;
        color: #58585b;
        font-weight: bold;
        min-width: 300px;


        svg {
            width: 30px;
            height: 30px;
            position: absolute;
            left: 15px;
            cursor: pointer;
            z-index: 200;
        }
    }
}
