﻿

.request-item {
    border-bottom: solid 1px #30c0c5;
    padding: 20px 0px 20px;

    .request-image-img {
        position: relative;
        display: inline-block;

        img {
            max-width: 250px;
        }
    }

    .request-content {
        display: inline-block;
        padding-left: 30px;
        vertical-align: top;
    }

    .img-title {
        position: relative;


        h4 {
            font-family: "adelle", serif;
            font-size: 20px;

            a {
                color: #5b5b5e;
            }
        }

        .downloadLink {
            display: inline;

            a {
                width: 30px;
                height: 50px;
                position: absolute;
                right: 0px;

                &:hover {circle {
    fill: #30c0c5 !important;
}
                         }
            }
        }
    }
}
