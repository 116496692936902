﻿@import "../mixins/breakpoints";
@import "variables/colors";
@import "variables/fonts";



.related-stories {
    /*padding: 0px 0px 40px 40px;*/
    margin: 20px 35px 45px;

    .related-story {
        min-width: 100%;
        min-height: 256px;
        background-size: cover;
        position: relative;
        margin: 10px 0px;
        background-position: center;
        cursor:pointer;

        .related-stories-icons {
    position: absolute;
    width: 34px;
    right: 5px;
    top: 6px;
    z-index: 350;


        a.lightbox-icon {
    width: 34px;
    height: 34px !important;
    max-height: 34px;
    cursor: pointer;
    position: relative;
}
        }
        /*height: 256px;
        background-color: #CCC;
        display: inline-block;
        
       ;
            margin: 0px 40px 20px 0px;*/
        .story-subline {
            min-height: 38px;
            position: absolute;
            display: inline-block;
            width: 100%;
            vertical-align: bottom;
            z-index: 150;
            background: $faded-gray;
            color: #FFF;
            padding: 8px 16px;
            bottom: 0px;
            font-family: $base-font;
            font-size: 16px;
            font-weight: 700;
        }

        &.story1 {
            background-image: url('/media/images/related-stories/aolvi003_ou508_h.jpg');
        }

        &.story2 {
            background-image: url('/media/images/related-stories/cksa009_yc167_h.jpg');
        }

        &.story3 {
            background-image: url('/media/images/related-stories/cksa027_yc526_h.jpg');
        }

        &.story4 {
            background-image: url('/media/images/related-stories/mbdmoti_ec001_h.jpg');
        }
    }
}


.no-results {
    font-family: "adelle", serif;
    color: #5b5b5e;
    font-size: 20px;
    padding: 0px 14px;
}

.image-search-results {
    margin: 20px 35px 45px;
       @include breakpoint(tablet) {
    margin: 0px 15px 45px;
    }

}

.search-results-paging {
    text-align: center;
    padding: 10px 0px 120px;

    button {
        background-color: #c2cb62;
        color: #FFF;
        padding: 10px 30px;
        border: 0px;
        font-size: 20px;
    }
}

.search-results-jump {
    margin: 0px 30px;

    label {
        background: #FFF;
        box-shadow: -1px -1px 5px #e9e9e9;
        padding: 11px 10px 0px;
        font-weight: 100;
        font-size: 11px;
        margin-bottom: 0px;
        color: #666;
        margin: 0px 0px -3px;
        position: relative;
        top: 0px;
    }

    button.page-bttn {
        background-color: #58585b;
        color: #FFF;
        padding: 7px 14px;
        border: 0px;
        font-size: 20px;
        border-right: solid 1px #FFF;
        border-bottom: solid 1px #FFF;
        margin-bottom: 0px;
        font-size: 15px;

        &.active {
            background-color: #30c0c5;
        }
    }
}



/*temp styles*/
.box-header {
    position: relative;
    opacity: 1;
    color: #787879;
    font-weight: 600;
    font-size: .85em;

    &.truncate {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.imagebox {
    display: block;
    width: 200px;
    height: 200px;
    background-color: #FFF;
    margin: 8px;
    overflow: hidden;
    position: relative;
}

.box-cover {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0px;
    background-size: cover;
    /*overflow: hidden;*/
}
.placeholder{
      flex-grow: 100;
      flex-basis: 240px;
      height: 0;
      margin: 0;
    }

.box-cover.grid {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0px;
    background-size: cover;
    .box-header.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    width: 100%;
    padding: 10px;
    bottom: -30px;
}
    img {
        position: absolute;
        vertical-align: bottom;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}




.lightbox-save-modal, .lightbox-download-modal, .lightbox-save-modal {
    position: absolute;
    z-index: 300;
    left: 15px;
    top: 80px;
    background-color: rgba(117, 104, 104, 0.65);
    color: #FFF;
    padding: 10px 10px;
    max-width: 310px;
    min-width: 310px;
    width: 100%;

  



    &.preview {
        background-color: rgba(40, 40, 40, 0.9);
        top: -60px;
        left: 100px;
    }

    .modal-section-title.new-lightbox {
        padding: 20px 0px 8px;
    }

    .modal-section-title.existing-lightbox {
        padding: 0px 0px 8px;
    }

    .modal-title {
        font-size: .8em;
        font-weight: 600;
        padding: 4px 0px 18px;
    }

    span.modal-label {
        font-size: 16px;
        padding-left: 6px;
        position: relative;
        top: -1px;
    }

    span.search-existing-icon svg {
        width: 16px;
    }

    span.search-new-icon svg {
        width: 16px;
    }

    button.bttn-create-lightbox {
        width: 100%;
        padding: 10px;
        background-color: #c2cb62;
        border: none;
    }

    .btn {
        padding: 10px;
        border: none;
        width: 100%;
        text-transform: uppercase;
        width: 100%;
        margin: 1px 0px;
        min-width: 200px;
        border-radius: 0px;

        &.btn-danger {
            background-color: #58585b !important;
        }

        &.btn-primary {
            background-color: #30c0c5;

            &:hover {
                background-color: #30c0c5;
            }
        }
    }


    .new-lightbox-input {
        background-color: #6f6f73;
        padding: 10px;
        border: none;
        width: 100%;
        max-width: 290px;
    }
}
.lightbox-save-modal.preview {
    top: -190px;
    left: -240px;
}

//bulk operations - modals

.lightbox-save-modal {
    &.bulk {
        z-index: 300;
        right: 30px;
        top: 0px;
        left: auto;
        text-align: left;
        min-width: 310px;

         &.mobile{
                display:none;
            }

        @include breakpoint(smallTablet) {
            display:none;

            &.mobile{
                display:block;
            }
            z-index: 300;
            right: 0px;
            top: 48px;
            left: 0px;
            text-align: left;
            margin: auto;
        }
    }
}
.lightbox-download-modal {
    &.bulk {
           z-index: 300;
    right: 38px;
    top: 0px;
    left: auto;
    text-align: left;
    min-width: 310px;

        &.mobile{
                display:none;
            }

        @include breakpoint(smallTablet) {
            display:none;

            &.mobile{
                display:block;
            }
         
             z-index: 300;
        right: 0px;
        top: 48px;
        left: 0px;
        text-align: left;
        margin:auto;
        }
    }
}

.lightbox-delete-modal {

    &.bulk {
        position: absolute;
        right: 24px;
        top: -4px;
        background: rgba(115, 126, 133, 0.85);
        color: #FFF;
        padding: 10px 10px;
        max-width: 260px;
        width: 250px;
        z-index: 304;
          &.mobile{
                display:none;
            }
       
        @include breakpoint(smallTablet) {
            display: none;

            &.mobile {
                display: block;
            }

            z-index: 300;
            right: 0px;
            top: 48px;
            left: 0px;
            text-align: left;
            margin: auto;
        }


        .modal-title {
            font-size: .8em;
            font-weight: 600;
            padding: 4px 0px 18px;
            text-transform: uppercase;
        }

        button {
            width: 49%;
            padding: 10px;
            background-color: #c2cb62;
            border: none;

            &.bttn-cancel-delete-lightbox {
                margin-left: 2%;
            }
        }
    }
}
