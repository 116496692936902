﻿.choose-everett {
    color: #FFF;
    font-family: "adelle", serif;
    font-size: 20px;
    line-height: 22px;
    background-color: #000;

    .cms-content {
        position: relative;
        padding: 90px 120px;


        @include breakpoint(tablet) {
            position: relative;
            padding: 90px 10px;
        }

        h3 {
            color: #c2cb62;
            font-weight: 800;
            margin: 50px 0px 4px;
        }

        .content-column {
            padding: 0px 30px;
        }
    }

    .choose-everett-background {
        background-image: url(/media/images/why_everett.jpg);
        background-size: cover;
        background-position: center;
        color: #FFF;
        font-family: "adelle", serif;
        font-size: 20px;
        line-height: 22px;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0px;
        filter: blur(1px);
        opacity: 0.55;
    }


    .everett-headline {
        text-align: center;
        padding-bottom: 60px;

        h1 {
            font-size: 50px;
            color: #FFF;
        }

        .why-tagline {
            font-size: 26px;
        }
    }
}
