﻿@import "mixins/breakpoints";
@import "variables/fonts";

.homepage-cta {
    position: absolute;
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(/media/images/MBDGIAN_EC111_H_cropped.jpg);
    min-height: 512px;
    -webkit-filter: blur(1px);
    -moz-filter: blur(1px);
    -o-filter: blur(1px);
    -ms-filter: blur(1px);
    filter: blur(1px);
    opacity: .85;
    background-position: center;
    background-size: cover;
    width: 100%;
    left: 0px;
}

//Adding new code

.homepagepublic-cta {
    position: absolute;
    background-image : url(/media/images/MBDOUOF_EC128_H.JPG);
    min-height: 512px;
    -webkit-filter: blur(0.5px);
    -moz-filter: blur(1px);
    -o-filter: blur(1px);
    -ms-filter: blur(1px);
    filter: blur(0.5px);
    opacity: .95;
    background-position: center;
    background-size: cover;
    width: 100%;
    left: 0px;
}

.cta-holder.container-fluid {
    min-height: 512px;
}

.cta-message {
    position: relative;
    max-width: 600px;
    margin: auto;
    text-align: center;
       padding: 120px 0px;

    @include breakpoint(tablet) {
        position: relative;
        max-width: 600px;
        margin: auto;
        text-align: center;
       padding: 90px 0px;
    }

    h3 {
        font-family: $base-font;
        color: #FFF;
        font-size: 30px;
        /*line-height: 38px;*/
        line-height: 25px;
        text-shadow: 0.5px 0.5px #000000;
        white-space: nowrap;
    }

    button#sign-up, a#sign-up {
        background-color: #c2cb62;
        color: #FFF;
        padding: 14px 30px;
        border: 0px;
        font-size: 20px;
        position: relative;
        top: 20px;
        background-color: #30c0c5;
        border-radius: 10px;
    }
}
