﻿@import "mixins/breakpoints";




   .td-fluid-search {
    display: inline-block;
    width: calc(100% - 48px);
}
   .td-fixed-40-search {
    display: inline-block;
}


.global-search {
    min-height: 70px;
    /*padding-bottom: 20px;*/
    .search-criteria {
        .arrow-down {
            position: absolute;
            top: 2px;
            right: 16px;
        }

        text-transform: uppercase;
    }

    .search-criteria-options {
        .arrow-down {
            position: absolute;
            top: 12px;
            right: 16px;
        }
    }

    .search-criteria-options {
        position: absolute;
        z-index: 450;
        background-color: #CCC;
        width: calc(100% - 3px);
        top: 54px;
        padding: 8px;

        &.collections-options {
            width: calc(100% + 50px);
            min-width: 295px;

            @include breakpoint(largeTablet) {
                width: calc(100% - 3px);
            }
        }

        &.filter-options {
            width: calc(100% + 50px);
            min-width: 295px;

            @include breakpoint(largeTablet) {
                width: calc(100% - 3px);
            }
        }

        &.subset-options {
            width: 100% !important;
            position: relative;
            top: 10px;
            margin: 0px 0px 0px 0px !important;
            padding: 0px;
            left: 0px;

            div.search-item {
                margin: 0px -10px !important;

                &:first-child {
                    border-top: solid 1px #DDD;
                }
            }
        }

        .search-item {
            cursor: pointer;
            background-color: #58585b;
            color: #FFF;
            padding: 10px !important;
            text-transform: uppercase;
            font-size: 14px;
            border-bottom: solid 1px #DDD;
            position: relative;

            button {
                width: 25px;
                height: 25px;
                background: none;
                border: solid 2px #CCC;
                position: absolute;
                right: 10px;
            }

            &.active {
                button {
                    background-color: #30c0c5;
                    border: solid 2px #FFF;
                }
            }

            &.sort-item.active {
                background-color: #30c0c5;
            }
        }
    }


    button.search-button {
        background-image: url(/media/images/search-button.jpg);
        width: 40px;
        height: 40px;
        border: none;
        display: inline-block;
        vertical-align: top;
        position: relative;
        /*top: 14px;*/
    }

    .search-selected-item {
        span {
            color: #c2cb62;
            font-size: .85em;
            text-transform: uppercase;

            &.glyphicon {
                color: #b5b6b5;
            }
        }
    }

    .search-items-01 {
        position: absolute;
        z-index: 200;
          @include breakpoint(largeTablet) {
              display:none;
              }
    }

    .search-selected-item {
        position: relative;
        display: inline-block;

        span.glyphicon {
            padding: 0px 8px 0px 5px;
        }
    }




    .search-options {
        position: relative;
        padding-top: 14px;
        height: 50px;

        .search-criteria {
            max-width: 100%;
            width: 100%;
            height: 41px;
            background-color: #6f6f73;
            color: #FFF;
            padding: 0px 10px !important;
            font-size: 16px;
            border: 0px;
            margin: -1px !important;
            display: inline-block;
            vertical-align: top;
            position: relative;
            line-height: 41px;


            &.active {
                background-color: #30c0c5;
            }
        }
    }

    .row.standard-search {
        border: solid 1px #787879;
        padding: 0px 0px 0px;
        border-bottom: solid 1px #FFF;
        position: relative;
        z-index: 301;
        background-color: #787879;

        .search-label-global {
            width: 140px;
            padding-left: 20px !important;
            display: table-cell;

            @include breakpoint(tablet) {
                display: block;
            }
        }

        .search-controls-global {
            width: 100%;
            display: table-cell;
            position: relative;
            top: 6px;

            @include breakpoint(tablet) {
                display: block;
            }
        }
    }

    .advanced-search-button {
        text-align: center;
        position: relative;


          @include breakpoint(largeTablet) {
                    padding: 10px 0px;
            }


        a {
            color: #FFF;
            font-size: 17px;
            text-decoration: none !important;
            position: relative;
            z-index: 303;

            &:hover {
                text-decoration: none;
            }
        }
    }


    div#search-slide {
        max-height: 0px;
        overflow: hidden;
        transition-property: all;
        transition-duration: .75s;

        &.active {
            max-height: 200px;

            @include breakpoint(desktop) {
                max-height: 280px;
            }
        }

        &.overflow {
            overflow: visible;
        }
    }

    .row.advanced-search {
        padding: 0px 0px 12px;
        padding-bottom: 24px;
        background-color: #787879;
        transition-property: all;
        transition-duration: .5s;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        top: 0px;
        margin-bottom: 0px !important;
        position: relative;
        /*display: table-row;*/
        .adv-search-label-global {
            width: 240px;
            display: table-cell;

            @include breakpoint(desktop) {
                display: block;
            }

            h3 {
                width: 240px;
                padding-left: 12px;
            }
        }

        .adv-search-controls-global {
            display: table-cell;
            width: 100%;

            @include breakpoint(desktop) {
                display: block;
            }
        }
    }

    h3.header-large {
        margin-top: 18px;
        font-size: 30px;
        padding-right: 20px;

        @include breakpoint(desktop) {
            margin-bottom: 0px;
        }
    }

    h3.header-medium {
        margin-top: 18px;
        font-size: 24px;
        padding-right: 20px;

        @include breakpoint(desktop) {
            margin-bottom: 0px;
        }
    }

    div {
        margin: 0px !important;
        padding: 0px 1px;
    }

    .search-drop {
        max-width: 100%;
        width: 100%;
        height: 41px;
        background-color: #6f6f73;
        color: #FFF;
        padding: 0px 10px;
        font-size: 16px;
        border: 0px;
        margin: -1px !important;
        display: inline-block;
        vertical-align: top;
        width: 100%;
        position: relative;
        top: 14px;

        @include breakpoint(largeTablet) {
            margin: 4px 0px !important;
        }
    }

    @include breakpoint(largeTablet) {
        .td-fluid {
            position: relative;
            top: -1px;

            .search-drop {
                margin: 0px !important;
            }
        }
    }

    input#search-input {
        max-width: 100%;
        width: 100%;
        height: 41px;
        background-color: #6f6f73;
        color: #FFF;
        padding: 0px 10px;
        font-size: 16px;
        border: 0px;
        margin: -1px !important;
        display: inline-block;
        vertical-align: top;
        position: relative;
    }
}
