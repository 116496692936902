﻿@import "mixins/breakpoints";
@import "variables/colors";
@import "variables/fonts";


h3.header-large {
    font-family: $base-font;
    color: #FFF;
    font-size: 32px;
}

h3.header-medium{
     font-family: $base-font;
    color: #FFF;
    font-size: 24px;
}

h3.small-header-text {
    font-family: $base-font;
    color: $subpage-header-color;
    font-size: 24px;

    span.sub-detail {
        color: #989996;
    }
}
