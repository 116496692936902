﻿section .imagegrid {
    &::after {
        content: '';
        flex-grow: 999999999;
    }
}

.img-preview {

    a:hover {
        color: #FFF;
    }
}

.imagegrid {
    width: 100%;
    color: #FFF;

    section {
        display: flex;
        flex-wrap: wrap;
    }

    section::after {
        content: '';
        flex-grow: 999999999;

        @include breakpoint(smallTablet) {
            flex-grow: 0;
        }
    }

    .page-label {
        font-family: "adelle", serif;
        color: #908e8e;
        font-size: 20px;
        width: 100%;
        padding: 30px 10px 4px;
        font-weight: 400;
    }

    a.imagebox {
        /*transition: width .5s, height .5s;*/
    }

    @include breakpoint(tablet) {
        /*.col-lg-2.ng-scope {
    
}*/
    }
}

.img-preview {
    position: fixed;
    z-index: 5000;
    width: 100%;
    top: 0px;
    height: 100%;
    color: #FFF;




    .image-info {
        padding-top: 30px;
    }

    div.keywords {
        padding: 5px 0px;
        line-height: 9px;
        max-height: 170px;
        overflow-y: auto;

        a.keywords {
            background: none;
            margin: 0px 3px;
            display: inline-block;
            padding: 0px 0px;
            color: #FFF;
            text-transform: uppercase;
            font-size: .98em;
            font-weight: 100;
            line-height: 16px;
        }
    }

    a.search-icon.link-download {
        padding-left: 4px;
    }

    .cls-1 {
        opacity: 1.0;
    }

    circle#ellipse-6 {
        fill: #30c0c5;
        opacity: 1.0;
    }

    .preview-image-specs svg {
        width: 36px;
    }

    a:focus, a:hover {
        /*color: #23527c;*/
        text-decoration: none;
    }

    .row.padding-below {
        padding-bottom: 6px;
    }

    .preview-background {
        padding: 40px;
        width: 50%;
        min-height: 500px;
        background-color: rgba(107, 107, 107, 0.97);
        position: relative;
        margin: auto;
        top: 50%;
        transform: translateY(-50%);

        span.spec-label {
            font-weight: 800;
            font-size: 12px;
            color: #FFF;
        }

        span.spec-value {
            font-size: 12px;
        }

        .close-preview {
            position: absolute;
            right: 7px;
            font-size: 26px;
            z-index: 500;
            cursor: pointer;
            height: auto;
            top: 7px;
        }

        .left-preview {
            position: absolute;
            left: 7px;
            top: calc(50% - 15px);
            font-size: 26px;
            z-index: 500;
            cursor: pointer;
            height: 100%;
            top: 0px;

            &.glyphicon-arrow-left:before {
                position: relative;
                top: calc(50% - 15px);
            }
        }

        .right-preview {
            position: absolute;
            right: 7px;
            font-size: 26px;
            z-index: 500;
            cursor: pointer;
            height: 100%;
            top: 0px;

            &.glyphicon-arrow-right:before {
                position: relative;
                top: calc(50% - 15px);
            }
        }

        img {
            width: 100%;
        }

        .landscape-view {
            text-align:center;
            img {
                max-height: 400px;
                width: auto;
            }
        }

        .potrait-view{
            text-align:center;
            img {
                max-height: 370px;
                width: auto;
            }
        }


        .display-table {
            position: absolute;
            height: 100%;
            top: 0px;
            right: 0px;
            padding-top: 40px;

            .preview-image-specs.position-bottom {
                /*position: absolute;
                bottom: 38px;*/
                position: relative;
                padding-top: 12px;
            }
        }
    }


    .box-header {
        color: #FFF;
        text-align: left;
    }
}

.spec-table {
    text-align: left;

    .modal-button {
        display: inline-block;
    }

    .lightbox-save-modal {
        left: -238px;
        top: -300px;

        &.no-permission {
            left: 0px;
            top: -46px;
        }
    }

    .lightbox-download-modal {
        left: -198px;
        top: -98px;

        &.no-permission {
            left: 0px;
            top: -46px;
        }
    }
}
