﻿
.daterangepicker {

    .input-mini {
        &.active {
            border: 1px solid #95989A;
        }

        border-radius: 4px;
        /* color: #FFF; */
        font-family: "adelle", serif;
        color: #58585B;
    }




    td.active, td.active:hover {
        background-color: #C0Ce5A !important;
        border-color: transparent !important;
        color: #fff !important;
    }

    td.in-range {
        background-color: #EAEAEA;
        border-color: transparent;
        color: #000;
        border-radius: 0;
    }

    .ranges li {
        font-size: 13px;
        background-color: #eaeaea;
        border: 1px solid #f5f5f5;
        border-radius: 4px;
        color: #000;
        padding: 3px 12px;
        margin-bottom: 8px;
        cursor: pointer;

        &.active {
            background-color: #2abec5 !important;
            border: 1px solid #2abec5 !important;
            color: #fff;

            &:hover {
                background-color: #2abec5 !important;
                border: 1px solid #2abec5 !important;
                color: #fff;
            }
        }
    }



    button.applyBtn.btn.btn-sm.btn-green, button.btn-success {
        background-color: #c0ce5a;
        border-color: #c0ce5a;
        color: #FFF;
        font-family: "adelle", serif;
         &:hover {
             background-color: #c0ce5a;
        border-color: #c0ce5a;
             }
    }

    .btn-default {
        background-color: #c4c4c4;
        border-color: #ccc;
        color: #FFF;
        font-family: "adelle", serif;
    }
}


input#daterange1 {
    padding: 20px 10px 18px !important;
    border: solid 1px #6F6F73;
    color: #6F6F73;
    padding: 0px 10px;
    font-size: 16px;
    border-radius: 0px;
    min-width: 340px;
    background-color: #2ABEC5;
    color: #FFF;
    font-family: "adelle", serif;
     padding: 2px 0px 0px 10px !important;
height: 36px;
margin-bottom: -14px;
    input{
    font-family: "adelle", serif;
    }
}


.search-history-page {
    width: 90%;
    margin: auto;
    padding: 20px 0px 60px;


    
    .control-input {
    display: inline-block;
    vertical-align: top;
    width: auto;
    min-width: 342px;
    padding: 0px;
}

    button.search-button {
    background-image: url(/media/images/search-button.jpg);
    width: 40px;
    height: 40px;
    border: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
}
    .search-input{
         max-width: 300px;
    width: 100%;
    height: 40px;
    background-color: #FFF;
    border: solid 1px #6F6F73;
    color: #6F6F73;
    padding: 0px 10px;
    font-size: 16px;
    margin: 0px 1px !important;
    display: inline-block;
    vertical-align: top;
    position: relative;
    }

    .date-range-selector{
        text-align:left;
            max-width: 260px;
    width: 100%;
    height: 40px;
     background-color: #FFF;
    border: solid 1px #6F6F73;
    color: #6F6F73;
    padding: 0px 10px !important;
    font-size: 16px;
   margin: 0px 1px !important;
    display: inline-block;
    vertical-align: top;
    position: relative;
    line-height: 40px;
        left: 4px;
        .arrow-down {
    position: absolute;
    top: 2px;
    right: 16px;
}
    }


    h4 {
        font-family: "adelle", serif;
        font-size: 20px;
        a{
             color: #5b5b5e;
        }
    }

    .sh_controls {
        text-align: right;
        padding: 20px 0px;
    }

    .history-item {
        padding: 10px 0px 20px;
        border-bottom: solid 1px #30c0c5;
        position: relative;
        color: #5b5b5e;
    }

    .search-criteria-option {
        display: inline-block;
        padding: 10px 30px 0px 0px;
        vertical-align: top;

        .options div {
            display: inline-block;
            background-color: #30c0c5;
            font-size: 14px;
            padding: 2px 5px 0px;
            margin: 0px 0px 3px;
            color: #FFF;
            font-weight: 800;
            text-transform:uppercase;
        }
        .options-row2 {
    font-weight: 800;
      text-transform:uppercase;
}
    }

    .history-date {
        position: absolute;
        right: 0px;
        top: 16px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;
    }
}
