﻿

.bulk-operations-item {
    display: inline-block;
    position: relative;
}


.glyphicon.spinning {
    font-size: 18px;
    animation: spin 1s infinite linear;
    -webkit-animation: spin2 1s infinite linear;
}

@keyframes spin {
    from { transform: scale(1) rotate(0deg); }
    to { transform: scale(1) rotate(360deg); }
}

@-webkit-keyframes spin2 {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}

a.navbar-brand.icon {
    background-repeat: no-repeat;
    background-position: center;
    width: 30px;
    height: 30px;
    margin: 10px;
    //icon_searchhist.svg
    &.search-history {
        background-image: url(/media/images/icons/icon_searchhist.svg);
    }

    &.download-history {
        background-image: url(/media/images/icons/icon_downloadhist.svg);
    }

    &.request-search {
        background-image: url(/media/images/icons/icon_notifications.svg);
    }

    &.account {
        background-image: url(/media/images/icons/icon_user.svg);
    }
     &.lightbox {
        background-image: url(/media/images/icons/icon_lightboxes.svg);
    }
}

.tabs.search-options{
    float: right;
    .cls-1 {
    fill: #bcbcbd;
    opacity: 0.595;
}
}



.lightbox-title-icons svg {
    max-width: 20px;
}

.related-icon {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 5px;
    cursor: pointer;

    &.link-story {
        background-image: url(/media/images/icons/icon_thumbnail_share.svg);
        top: 5px;
    }

    &.add-story {
        background-image: url(/media/images/icons/icon_thumbnail_addto.svg);
        top: 40px;
    }
}



a.login.link-checkbox {
    text-decoration: none !important;
 svg {
        width: 30px;
    height: 30px;
    position: relative;
    right: 15px;
    cursor: pointer;
    z-index: 200;
        left: 0px;
        top: 14px;

        .cls-checkmark,.cls-1 {
    fill: #FFF;
    opacity: 1;
}
    }
 }



.list-images {
    position: relative;
    display: inline-block;
    margin: 10px;
    transition: width .5s, height .5s;

    &.flex {
        margin: 4px 4px 30px;
    }

    &:hover {
        .search-icon.link-checkbox.default-state {
            display: block;


            .cls-1 {
                fill: #30c0c5;
                /*opacity: 1;*/
            }
        }
    }

    .search-icon {
        width: 30px;
        height: 30px;
        position: absolute;
        right: 15px;
        cursor: pointer;
        z-index: 200;

        &.link-checkbox {
            left: 15px;
            top: 14px;

            &.default-state {
                /*display: none;*/
            }
        }


        


        &.link-save-to {
            // background-image: url(/media/images/icons/icon_thumbnail_saveto.svg);
            top: 15px;
        }

        &.link-delete{
             top: 85px;
        }

        &.btn-active g {
            opacity: 1.0;
        }

        &.btn-active circle {
            fill: #30c0c5 !important;
        }

        &.btn-active path {
            fill: #FFF;
        }

        &.link-download {
            // background-image: url(/media/images/icons/icon_thumbnail_download.svg);
            top: 50px;
        }

        &.link-view-image {
            background-image: url(/media/images/icons/icon_thumbnail_preview.svg);
            bottom: 46px;
        }

        &.link-lock-image {
            background-image: url(/media/images/icons/icon_thumbnail_request.svg);
            top: 50px;
        }
    }
}


    .search-control-group {
    display: inline-block;
    min-width:150px;

     @include breakpoint(mobileMiddle) {
         &.resizer{
             display:none;
         }
         }
}

.search-options {
    padding: 20px 0px 0px;
    height: 50px;



   


    span.search-label {
        font-size: 12px;
        padding: 0px 5px 0px 30px;
        color: #828383;
        position: relative;
        top: -5px;
    }

    a.image-links {
        background-repeat: no-repeat;
        background-position: center;
        margin: 6px;
        height: 10px;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }
        // background-color: #a7a8a7;
        /*padding: 1px 7px;*/
        svg {
            width: 30px;
        }

        &.save-to {
            // padding: 2px 10px;
            /*background-image: url(/media/images/icons/icon_saveto.svg);*/
            /*-webkit-mask: url(/media/images/icons/icon_saveto.svg) no-repeat 50% 50%;
            mask: url(/media/images/icons/icon_saveto.svg) no-repeat 50% 50%;*/
            background-size: 16px;

            svg {
                width: 22px;
            }


            &.active {
                //  background-color: #30c0c5;
            }
        }

        &.download {
            /*background-image: url(/media/images/icons/icon_download.svg);*/
            /*padding: 2px 14px;*/
            /*-webkit-mask: url(/media/images/icons/icon_download.svg) no-repeat 50% 50%;
            mask: url(/media/images/icons/icon_download.svg) no-repeat 50% 50%;*/
            background-size: 22px;

            &.active {
                background-color: #30c0c5;
            }
        }

        &.request {
            /*background-image: url(/media/images/icons/icon_request.svg);*/
            /*padding: 1px 7px;*/
            /*-webkit-mask: url(/media/images/icons/icon_request.svg) no-repeat 50% 50%;
            mask: url(/media/images/icons/icon_request.svg) no-repeat 50% 50%;*/
            background-size: 12px;

            svg {
                width: 18px;
            }

            &.active {
                background-color: #30c0c5;
            }
        }

        &.icon-smaller {
            // padding: 4px 12px;
            /*background-image: url(/media/images/icons/icon_vew_smallgrid.svg);*/
            /*-webkit-mask: url(/media/images/icons/icon_vew_smallgrid.svg) no-repeat 50% 50%;
            mask: url(/media/images/icons/icon_vew_smallgrid.svg) no-repeat 50% 50%;*/
            svg {
                width: 25px;
            }

            &.active {
                svg path {
                    fill: #30c0c5;
                    opacity: 1.0;
                }
            }
        }

        &.icon-larger {
            /*padding: 4px 12px;*/
            /*background-image: url(/media/images/icons/icon_view_biggrid.svg);*/
            /*-webkit-mask: url(/media/images/icons/icon_view_biggrid.svg) no-repeat 50% 50%;
            mask: url(/media/images/icons/icon_view_biggrid.svg) no-repeat 50% 50%;*/
            svg {
                width: 25px;
            }

            &.active {

                svg path {
                    fill: #30c0c5;
                    opacity: 1.0;
                }
            }
        }
    }
}
