﻿@import "mixins/breakpoints";
@import "variables/colors";
@import "variables/fonts";

.home-tagline {
    width: 100%;
    min-height: 50px;
    position: absolute;
    background-color:  $faded-gray;
    bottom: 0px;
    z-index: 200;

    .tagline {
        font-family: $base-font;
        color: #FFF;
        position: relative;
        z-index: 140;
        text-align: center;
        font-size: 22px;
        padding: 10px;
    }
}
