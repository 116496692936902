﻿

.kill-record {
    position: absolute;
        top: calc(50% - 11px);
    right: 10px;
    font-size: 21px;
    color: #FFF;
    cursor:pointer;
}

.gallery-home-img{
        min-height: 100% !important;
    background-size: cover;
    background-position: top center;
}

div.admin-home-featured {
    .global-edit-icon {
        position: absolute;
        top: 70px;
        right: 10px;
        z-index: 200;
        padding: 2px 10px 8px;
        color: #FFF;
        background-color: rgba(66, 63, 63, 0.56);

        span.edit-type {
            padding-left: 6px;
        }

        span.glyphicon.glyphicon-cog.gear-icon {
            text-shadow: 0 0 5px #575252;
            position: relative;
            right: auto;
            top: 7px;
            color: #FFF;
            cursor: pointer;
            z-index: 705;
        }
    }
}


.lightbox-share-modal, .lightbox-clone-modal {
    position: absolute;
    left: -215px;
    top: 43px;
    background: rgba(115, 126, 133, 0.85);
    color: #FFF;
    padding: 10px 10px;
    max-width: 260px;
    width: 250px;
    z-index: 304;

    .modal-title {
        font-size: .8em;
        font-weight: 600;
        padding: 4px 0px 2px;
        text-transform: uppercase;
    }

    button {
        width: 100%;
        padding: 10px;
        background-color: #58585b;
        border: none;

        &.active {
            background-color: #c2cb62;
        }
    }
}

.lightbox-clone-modal {
    top: 76px;
}

span.lightbox-icon svg {
    width: 16px;
}
a.image-click {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
    }
.new-lightbox-input {
    background-color: rgb(111, 111, 115);
    width: 100%;
    padding: 10px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
}

.modal-lightbox input {
    margin: 2px 0px;
}

.modal-section-title {
    padding: 12px 0px 2px;
}

span.modal-label {
    font-size: 16px;
    padding-left: 6px;
    position: relative;
    top: -1px;
}


.home-lightbox-collection {
    padding: 30px 0px;
    padding-right: 0px !important;
    padding-left: 0px !important;
    overflow: hidden;





    .lightbox-delete-modal {
        position: absolute;
        left: -215px;
        top: 90px;
        background: rgba(115, 126, 133, 0.85);
        color: #FFF;
        padding: 10px 10px;
        max-width: 260px;
        width: 250px;
        z-index: 304;

        .modal-title {
            font-size: .8em;
            font-weight: 600;
            padding: 4px 0px 18px;
            text-transform: uppercase;
        }

        button {
            width: 49%;
            padding: 10px;
            background-color: #c2cb62;
            border: none;

            &.bttn-cancel-delete-lightbox {
                margin-left: 2%;
            }
        }
    }

    .lightbox-collection-item.homepage {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .lightbox-option-icons {
        position: absolute;
        width: 40px;
        right: 5px;
        top: 6px;
        z-index: 350;

        a.lightbox-icon {
            width: 40px;
            height: 40px !important;
            max-height: 40px;
            cursor: pointer;
            position: relative;

            &.btn-active {
                g.cls-1 {
                    opacity: 1.0;
                }

                circle {
                    fill: rgb(48, 192, 197);
                }
            }
        }
    }



    .lightbox-title {
        background-color: rgba(86, 85, 85, 0.82);
        padding: 10px;
        position: relative;
        top: 269px;
        color: #FFF;
        font-family: "adelle", serif;
        font-size: 16px;
        font-weight: 600;
        z-index: 301;
        max-height: 42px;

        input {
            color: #666;
            position: relative;
            top: -6px;
            width: calc(100% - 42px);
            padding: 4px 0px;
            left: -6px;
        }

        .edit-icon {
            position: absolute;
            width: 20px;
            top: 9px;
            right: 14px;
        }

        .glyphicon {
            cursor: pointer;
        }
    }

    .lightbox-section {
        .tabs.search-options {
            float: none;
            text-align: right;
            position: relative;
            top: 38px;

            @include breakpoint(desktop) {
                top: -22px;
            }

            @include breakpoint(largeTablet) {
                top: 78px;
            }
        }

        .lightbox-controllers {
            display: inline-block;
            margin-right: 30px;
            position: relative;
            top: 10px;

            @include breakpoint(desktop) {
                display: block;
                margin-right: 30px;
                position: relative;
                top: -20px;
            }

            @include breakpoint(largeTablet) {
                display: inline-block;
                margin-right: 30px;
                position: relative;
                top: 32px;
            }

            @include breakpoint(mobile) {
                display: block;
            }
        }


        .control-input {
            display: inline-block;
            position: relative;
            left: -40px;
            top: 4px;

            @include breakpoint(desktop) {
                left: -32px;
                text-align: right;
                top: 56px;

                input#daterange1 {
                    min-width: 210px !important;
                }
            }

            @include breakpoint(largeTablet) {
                left: 28px;
                text-align: right;
                top: 24px;

                input#daterange1 {
                    min-width: 210px !important;
                }
            }

            @include breakpoint(mobile) {
                left: 0px;
                left: -35px;

                input#daterange1 {
                    min-width: 210px !important;
                }
            }



            span.search-label {
                font-size: 12px;
                padding: 0px 5px 0px 30px;
                color: #828383;
                display: inline-block;
                position: absolute;
                width: 130px;
                left: -140px;
                top: 4px;


                @include breakpoint(mobilePotrait) {
                    position: relative;
                    width: 130px;
                    left: 0px;
                    top: 0px;
                    display:none;
                }
            }
        }
    }

    .collection-area {
        background-color: #f7f9f4;
        padding: 30px;
        /*padding-right: 46px;*/
        border-top: #e6e7e6 solid 2px;

        @include breakpoint(largeTablet) {
            padding-top: 60px;
        }

        @include breakpoint(mobile) {
            padding-top: 90px;
        }

        @include breakpoint(mobilePotrait) {
            padding-top: 120px;
        }
    }

    .imagebox {
        display: block;
        width: 100%;
        height: 200px;
        background: none;
        margin: 8px;
        overflow: visible;
        position: relative;
        min-height: 330px;
        min-width: 200px;

        .box-header {
            text-align: left;
            text-transform: uppercase;
        }

        .box-cover {
            background-color: rgb(241, 241, 241);
            background-position: center top;
            max-height: 311px;
        }
    }

    .small-thumbs .imagebox {
        min-height: 70px;
        min-width: 120px;
        height: 170px;

        .lightbox-title {
            top: 129px;
        }
    }

    .lb-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 35px);
    }


    .lightbox-tab-collection {
        padding-left: 34px;

        @include breakpoint(mobile) {
            padding-left: 0px;
        }

        .tabs {
            padding: 12px;
            display: inline-block;
            position: relative;
            top: 2px;
            padding-bottom: 4px;


            a {
                font-family: "adelle", serif;
                color: #bcbcbd;
                font-size: 24px;
                text-transform: uppercase;

                @include breakpoint(smallTablet) {
                    font-size: 16px;
                }

                @include breakpoint(mobilePotrait) {
                    font-size: 12px;
                }



                &:hover {
                    text-decoration: none !important;
                }

                &:focus {
                    text-decoration: none !important;
                }
            }

            &.active {
                background-color: #f7f9f4;
                border: #e6e7e6 solid 2px;
                border-bottom: none;
                padding: 10px;

                a {
                    color: #58585b;
                }
            }
        }
    }
}

.home-drop {
    width: 100%;
    height: 700px;
    position: absolute;
    top: 0px;
    overflow: hidden;
    left: 0px;
    z-index: 4;


    .lightbox-info {
        cursor: default !important;
    }

    &.full-width {
        padding: 0px;
        
        //border: solid 2px #FF0000;
        .lightbox-item {
            width: 100% !important;
            padding: 0px;
            .lightbox-info {
                min-height: 700px;
                background-size: cover;
                background-position: center top;
                vertical-align: top;
                position: relative;
                cursor: pointer;
                background-attachment: fixed;

                @include breakpoint(tablet) {
                    background-attachment: inherit;
                }               
            }
        }
    }
}

.home {



    &.hero-graphic {
        /*background-image: url(/media/images/M8DNOBY_EC007_H.JPG);*/
        height: 700px;
        background-position: center;
        background-size: cover;
        position: relative;

        .container-fluid.top-nav {
            position: relative;
            z-index: 220;

            @include breakpoint(tablet) {


                a.navbar-brand.account-holder {
                    max-width: 200px;
                    position: relative;
                    top: -6px;
                }
            }
        }
    }

    &.blur {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 600px;
        -webkit-filter: blur(5px);
        -moz-filter: blur(5px);
        -o-filter: blur(5px);
        -ms-filter: blur(5px);
        filter: blur(5px);
        opacity: .2;
    }

    .blur-container {
        height: 60px;
        overflow: hidden;
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
    }

    .login-controller {
        max-width: 500px;
        width: 90%;
        padding: 20px 0px 40px;
        position: relative;
        z-index: 110;
        top: 102px;
        margin: auto;
        overflow: hidden;
        background: rgba(115, 126, 133, 0.85);

        .forgot-password {
            text-align: center;
            padding: 20px 0px 0px;

            a {
                color: #FFF;
            }
        }

        .forgot-text {
            color: #FFF;
            padding: 40px;

            a {
                color: #FFF;
            }
        }

        .close-preview {
            color: #fff;
            position: absolute;
            right: 7px;
            top: 5px;
            width: 20px;
            height: 20px;
            font-size: 22px;
        }

        h3.header-large {
            text-align: center;
            margin-bottom: 40px;
            font-size: 38px;
        }

        .forgot-text {
            color: #FFF;
            padding: 10px 45px 0px;

            .forgot.header {
                h3.header-large {
                    margin-bottom: 10px;
                }
            }

            .forgot-field-label {
                font-family: "adelle", serif;
                font-size: 20px;
                font-weight: 400;
                position: relative;
                top: 4px;
                left: 4px;
            }

            .row.forgot-fields {
                padding-bottom: 8px;
                margin: 0px;
            }
        }

        .col-md-12.forgot-message {
            padding-bottom: 30px;
        }


        input.input-field {
            width: 100%;
            height: 41px;
            background-color: #58585b;
            color: #FFF;
            padding: 0px 10px;
            font-size: 16px;
            border: 0px;
            margin: -1px !important;
            display: inline-block;
            vertical-align: top;
        }

        .login-fields {
            max-width: 284px;
            margin: auto;
            text-align: left;
            padding: 2px;



            &.remember {
                font-family: "adelle", serif;
                color: #FFF;
                font-size: 19px;
                height: 60px;
            }

            div.login-checkbox {
                width: 26px;
                height: 26px;
                display: inline-block;
                vertical-align: middle;
                border: solid 3px #CCC;
                border-radius: 6px;
            }

            label.lbl_remember {
                font-weight: 400;
                position: relative;
                top: 4px;
                left: 4px;
            }
        }



        button.login-button {
            width: 100%;
            padding: 10px;
            background-color: #c2cb62;
            border: none;
            max-width: 280px;
            color: #FFF;
            font-size: 20px;
            box-shadow: 0 0 1em #666;
        }
    }
}
.row-buttons {

        padding: 0px 8px;
    .col-md-6 {
        padding: 0px 4px !important;
    }

    button {
            background-color: #30c0c5;
        color: #FFF;
        padding: 10px 30px;
        border: 0px;
        font-size: 20px;
        margin: auto;
        float: none;
        margin-top: 20px;
        margin: 0px 0px 10px;
        width: 100%;
    }
 }

.terms-controller {
    position: absolute;
    z-index: 5000;
    width: 75%;
    background: #FFF;
    padding: 40px;
    height: 100%;
    margin: auto;
    overflow: auto;
    margin-left: 10%;
    margin-top: 5%;

    .terms-buttons {
    text-align: center;

    button{
             width: 100%;
    padding: 10px;
    background-color: #c2cb62;
    border: none;
    max-width: 280px;
    color: #FFF;
    font-size: 20px;
    box-shadow: 0 0 1em #666;
    margin: 20px;
    text-transform: uppercase;
    &.terms-not-accept{
            background-color: #b81010;
    }
    }
}

}
