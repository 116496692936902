﻿@import "mixins/breakpoints";
@import "variables/colors"; 
@import "variables/fonts"; 
/*custom rows*/

body{
        display: flex;
    min-height: 100vh;
    flex-direction: column;
    background-color: #f7f9f4;
}

.cursor-pointer{
    cursor:pointer;
}
input.ng-invalid.ng-touched {
    border:solid 1px #FF0000;
}
.message-area{
    width:200px;
    height:20px;
}
.update-message {
    color: #FF0000;
     visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;

    &.updated{
        visibility: visible;
    opacity: 1;
    transition: opacity 0.3s, visibility 0.3s;
    }
}


.cms-content {
    padding: 40px;


     @include breakpoint(largeTablet) {
                 padding: 10px;
            }
      

    h1,h2,h3{
        font-family: "adelle", serif;
        margin-bottom: 20px;
        color:#58585B;

    }
}
.navbar-header {
    min-height: 60px;
}

 input.request-research-title {
        background-color: #58585b;
    border: none;
    width: 100%;
    padding: 10px;
     max-width: 100%;
}

    textarea.request-research-message {
    width: 100% !important;
    max-width: 100%;
    padding: 10px;
    min-height: 200px;
        background-color: #58585b;
    border: none;
}


h1.headline{
    font-family: "adelle", serif;
    color: #58585b;
    font-size: 36px;
    text-transform: uppercase;

}

.navbar-menu {
    position: absolute;
    top: 63px;
    right: 0px;


    .lightbox-item {
        a.btn.btn-scroll {
            padding: 10px;
            border: none;
            width: 100%;
            text-transform: uppercase;
            width: 100%;
            margin: 1px 0px;
            min-width: 200px;
            border-radius: 0px;
            background-color: rgba(133, 145, 154, 0.85);
            color: #FFF;
            text-align:center;
        }
    }

    .navbar-menu-item {
        padding: 10px;
        width: 270px;
        background-color: rgba(133, 145, 154, 0.85);
        margin-bottom: 1px;
        position: relative;
        transition: opacity .1s linear;
        color: #FFF;



        &.kill {
            opacity: 0.0;
        }

        .img-caption {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #FFF;
            width: 80%;
        }

        a {
            color: #FFF;
            text-transform: uppercase;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: calc(100% - 15px);
            text-align: left;

            &.bttn-lb {
                background: #666;
                padding: 4px 5px;

                &.bttn-lb.active {
                    background: #30c0c5 !important;
                }
            }
        }

        .history-date {
            color: #FFF;
        }
    }
}
.global-edit-icon{
span.glyphicon.glyphicon-cog.gear-icon {
    position: absolute;
    right: 6px;
    top: 4px;
    color: #FFF;
    cursor:pointer;
    z-index:705;
}
}
.content-area {
    max-width: 1200px;
    margin: auto;

    h3.header-large {
        color: #222;
        span.username{
            font-family: "adelle", serif;
    color: #5b5b5e;
    font-size: 24px;
        }
    }
     h3.header-small {
       font-family: "adelle", serif;
    color: #5b5b5e;
    font-size: 24px;
    }

    button {
        /* width: 100%; */
        padding: 6px 30px;
        background-color: #c2cb62;
        border: none;
        color: #FFF;
    }

    ul.alpha-list li {
        display: inline;
            font-size: 22px;
    text-transform: uppercase;
    }
    .user-results {
    padding-top: 20px;
    padding-bottom: 20px;
}
}
.screenReaderText { 
  border: 0; 
  clip: rect(0 0 0 0); 
  height: 1px; 
  margin: -1px; 
  overflow: hidden; 
  padding: 0; 
  position: absolute; 
  width: 1px; 
}
.row-eq-height{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;


       @include breakpoint(largeTablet) {
                display: block;
            }
}
.top-buffer { margin-top:20px; }


:focus {
  outline-color: transparent;
  outline-style: none;
}

.content {
    flex: 1;
}

.tbl-row {
    display: table-row;
}
.td-fluid {
    display: table-cell;
    width: 100%;
        padding-right: 2px;
}
.td-fixed-40 {
    display: table-cell;
    width: 40px;
        padding-right: 2px;
}

.nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.modal-button {

    .lightbox-delete-modal {
        position: absolute;
           right: 50px;
        top: 90px;
        background: rgba(115, 126, 133, 0.85);
        color: #FFF;
        padding: 10px 10px;
        max-width: 260px;
        width: 250px;
        z-index: 304;
        .modal-title {
    font-size: .8em;
    font-weight: 600;
    padding: 4px 0px 18px;
    text-transform: uppercase;
}
        button {
            width: 49%;
            padding: 10px;
            background-color: #c2cb62;
            border: none;

            &.bttn-cancel-delete-lightbox {
                margin-left: 2%;
            }
        }
    }
}


.header-section {
    text-align: center;
    max-width: 770px;
    margin: auto;

    h1{
        margin-bottom:4px !important;
    }
    h2 {
        font-size: 24px;
        margin-top:0px !important;
    }

    p {
        font-family: "adelle", serif;
        color: #58585B;
        font-size: 18px;
    }
}

.row.color-section {
    margin-top: 140px;

    &.row2 {
        margin-top: 40px;
    }

    .pattern-set-headline {
        border-bottom: solid 1px #58585B;
        padding: 10px 0px;
        margin-bottom: 30px;
        font-size: 20px;
        font-style: italic;
        color: #58585B;
        max-width: 740px;
    }

    .color-item {
        width: 24%;
        display: inline-block;
        max-width: 200px;
        vertical-align: top;

        .color-swatch {
            width: 160px;
            height: 160px;
            background-color: #CCC;

            &.everett-blue {
                background-color: #2ABEC5;
            }

            &.everett-green {
                background-color: #C0CE5A;
            }

            &.background-white {
                border: solid 1px #58585B;
                background-color: #FFF;
            }

            &.background-gray {
                background-color: #58585B;
            }

            &.yellow-hightlight {
                background-color: #E4F084;
            }

            &.dark-gray {
                background-color: #373739;
            }

            &.dark-blue {
                background-color: #31969D;
            }

            &.light-blue {
                background-color: #B1E0E2;
            }

            &.background-blur {
                background: rgba(115, 126, 133, 0.85);
            }
        }

        .hex-color, .hex-description {
            font-weight: bold;
            color: #58585B;
            padding: 10px 0px 4px;
        }

        .hex-description {
            padding: 0px;
        }
    }

    .site-fonts {
        span {
            line-height: 35px;
        }

        .italic {
            font-style: italic !important;
        }

        .adell-pe-bold {
            font-size: 20px;
            font-family: "adelle", serif;
            font-weight: 700;
            font-style: normal;
        }

        .adell-pe-reg {
            font-size: 20px;
            font-family: "adelle", serif;
            font-weight: 400;
            font-style: normal;
        }

        .adell-sans-bold {
            font-size: 20px;
            font-family: adelle-sans, sans-serif;
            font-weight: 700;
            font-style: normal;
        }

        .adell-sans-reg {
            font-size: 20px;
            font-family: adelle-sans, sans-serif;
            font-weight: 400;
            font-style: normal;
        }
    }

    .site-headings {

        h1 {
            font-family: "adelle", serif;
            margin-bottom: 20px;
            color: #58585B;
        }

        h2 {
            font-family: "adelle", serif;
            margin-bottom: 0px;
            color: #58585B;
        }

        h3 {
            font-family: "adelle", serif;
            color: #5b5b5e;
            font-size: 24px;
            display: inline-block;
            margin-bottom: 4px;
        }

        h4 {
            font-size: 20px;
            font-family: adelle-sans, sans-serif;
            font-weight: 700;
            font-style: normal;
            color: #95989a;
            margin-bottom: 18px;
        }

        h5 {
            font-size: 16px;
            font-family: adelle-sans, sans-serif;
            font-weight: 700;
            font-style: normal;
            color: #7092ac;
        }
    }

    .inline-elements {
        .thumbnail-text {
            font-family: adelle-sans, sans-serif !important;
            position: relative;
            opacity: 1;
            color: #787879;
            font-weight: 600;
            font-size: .85em;
            /* font-family: adelle-sans, sans-serif; */
        }

        .body-copy {
            font-family: adelle-sans, sans-serif !important;
            font-size: 14px;
            line-height: 1.42857143;
            color: #333;
        }

        .gallery-copy {
            font-family: "adelle", serif;
            line-height: 1.42857143;
            color: #333;
            
        }
    }
}


div.fixedHeader {
    position: fixed;
    width: 97%;
    z-index: 3000;
    background: #FFF;
    top: 68px;
}

.search-icon.link-checkbox .cls-1 {
    opacity: 1;
    fill: #30c0c5;
    opacity: 1 !important;
}

a.sort-link {
    padding: 4px 18px;
    color:#58585B;
    text-decoration:none !important;
    background-color: #f1f1f1;
    &.active {        
        background-color: #30c0c5;
        color: #FFF;
    }
}

.lb_search {
    position: relative;
    top: -16px;

      @include breakpoint(largeTablet) {
    top: 12px;
    }
       @include breakpoint(mobileMiddle) {
    top: -20px;
    }


      

    .search-button {
        padding: 6px 18px;
        color: #58585B;
        text-decoration: none !important;
        background-color: #f1f1f1;

        &:hover {
            background-color: #30c0c5;           
            color: #FFF;
        }
    }
}