﻿@import "mixins/breakpoints";
@import "variables/fonts"; 

footer {
    min-height: 242px;
    background-color: #58585b;

    @include breakpoint(tablet) {
        .footer-message {
            text-align: center;
        }
    }


    .footer-message h3 {
        font-family: $base-font;
        color: #FFF;
        font-size: 22px;
        line-height: 28px;
        font-weight: 700;
    }

   

    a#find-out {
    background-color: #c2cb62;
    color: #FFF;
    padding: 14px 30px;
    border: 0px;
    font-size: 20px;
    position: relative;
    top: 20px;
}


    .footer-logo {
        text-align: center;
        margin: 50px 0px 10px;
    }

    .footer-phone {
         font-family: $base-font;
          font-size: 22px;
        line-height: 28px;
        font-weight: 700;
        text-align: center;
        a{
       
        color: #FFF;
       
        }
    }

    .footer-social {
        text-align: center;

        .dv-social-item {
            display: inline-block;
            margin: 40px 6px 20px;
        }
        
    }

    .footer-links {
        text-align: center;

        .link-item {
            font-family: $base-font;
            font-size: 15px;
            font-weight: 600;
            line-height: 22px;

            a {
                color: #FFF;
            }
        }
    }

    .col-md-12.copyright {
        text-align: center;
        color: #FFF;
        height: 40px;
        font-size: 12px;

        @include breakpoint(tablet) {
             padding-top: 15px;
        }
    }
}
