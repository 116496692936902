﻿@import "mixins/breakpoints";
@import "variables/fonts";

.sub-page {

    .fixed-nav {
    min-height: 60px;
    width: 100%;
}


    ul.nav.navbar-nav.public {
       position:relative;
       top:-4px;
    }


    .navbar-inverse {
        background-color: #58585b;
        border: none;
       
        margin-bottom: 0px;
        border-radius: 0px;
                position: fixed;
    width: 100%;
    z-index: 4000;
    }

    .container {
        position: relative;
        z-index: 100;
    }

    div#navbar {
        position: relative;
        z-index: 700;

        @include breakpoint(tablet) {
            position: relative;
                z-index: 600;
            background: none;
            overflow:visible;
        }
    }

    ul.nav.navbar-nav li {
        width: auto;

        &.account {
            width: auto;

            a {
                font-family: arial !important;
                font-size: 12px !important;
                position: relative;
                top: 4px;
            }
        }
    }

    .navbar-inverse .navbar-nav > li > a {
        color: #FFF;
        font-family: "adelle",serif;
        margin-left: 0px !important;
        font-size: 14px;
            position: relative;
    top: 4px;
    }

    .navbar-header {
        position: relative;
        z-index: 701;
    }

    form.navbar-form.navbar-right {
        margin-top: 0px !important;
        border: 0px;
        box-shadow: none;
    }

    img.header-logo {
        width: 200px;
        margin: 8px -5px;

        @include breakpoint(tablet) {
            width: 200px;
            margin: 8px 10px;
        }
    }

    @include breakpoint(tablet) {
        #navbar {
            overflow-y: visible;
            background: rgba(115, 126, 133, 1.0);

            .navbar-menu {
                /* background-color: rgba(133,145,154,.85); */
                position: absolute;
                top: 63px;
                /* padding: 10px; */
                width: 270px;
                text-transform: uppercase;
                left: 0px;
                width: 100% !important;
                min-width: 260px;

                @include breakpoint(tablet) {
                    top: 49px;
                    z-index: 300;
                }

                .navbar-menu-item {
                    padding: 10px;
                    width: 100%;
                    background-color: rgba(133, 145, 154, 1.0);
                    margin-bottom: 0px;
                    position: relative;
                    transition: opacity .1s linear;
                    border-bottom: solid 1px #f1f1f1;
                }
            }
        }
    }
}
